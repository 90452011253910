define("discourse/plugins/moyun/discourse/controllers/notification", ["exports", "@ember/controller", "discourse/lib/ajax", "@glimmer/tracking", "@ember/service", "@ember/object"], function (_exports, _controller, _ajax, _tracking, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let NotificationController = _exports.default = (_class = class NotificationController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "modal", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "currentUser", _descriptor3, this);
      _initializerDefineProperty(this, "dialog", _descriptor4, this);
      _initializerDefineProperty(this, "activeTab", _descriptor5, this);
      _defineProperty(this, "tabList", [{
        title: "Like"
      }, {
        title: "Comment"
      }, {
        title: "Notice"
      }]);
      _initializerDefineProperty(this, "likeList", _descriptor6, this);
      _initializerDefineProperty(this, "commentList", _descriptor7, this);
      _initializerDefineProperty(this, "noticeList", _descriptor8, this);
      _initializerDefineProperty(this, "pendingList", _descriptor9, this);
    }
    async init() {
      super.init(...arguments);
      this.getLikeList();
    }
    getLikeList() {
      (0, _ajax.ajax)("/notifications", {
        data: {
          limit: 30,
          recent: true,
          bump_last_seen_reviewable: true,
          filter_by_types: "liked,liked_consolidated,reaction",
          silent: true
        }
      }).then(res => {
        console.log('111111', res);
        this.likeList = res.notifications;
      });
    }
    getCommentList() {
      (0, _ajax.ajax)("/notifications", {
        data: {
          limit: 30,
          recent: true,
          bump_last_seen_reviewable: true,
          filter_by_types: "mentioned,group_mentioned,posted,quoted,replied",
          silent: true
        }
      }).then(res => {
        console.log('222', res);
        this.commentList = res.notifications;
      });
    }
    getNoticeList() {
      (0, _ajax.ajax)("/notifications", {
        data: {
          limit: 30,
          recent: true,
          bump_last_seen_reviewable: true
        }
      }).then(res => {
        console.log('333', res);
        this.noticeList = res.notifications;
        this.pendingList = res.pending_reviewables;
      });
    }
    switchTab(param) {
      this.activeTab = param;
      switch (param) {
        case 0:
          this.getLikeList();
          break;
        case 1:
          this.getCommentList();
          break;
        case 2:
          this.getNoticeList();
          break;
        default:
          break;
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "dialog", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "activeTab", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "likeList", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "commentList", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "noticeList", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "pendingList", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "getLikeList", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getLikeList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getCommentList", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getCommentList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getNoticeList", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getNoticeList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "switchTab", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "switchTab"), _class.prototype)), _class);
});