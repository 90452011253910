define("discourse/plugins/moyun/discourse/controllers/ig", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse/components/dialog-messages/group-delete", "discourse-common/utils/decorators", "discourse-i18n", "discourse/lib/ajax", "@ember/object/computed", "discourse/lib/pwa-utils", "@ember/application", "discourse/components/modal/share-topic", "discourse/lib/url", "discourse/models/category"], function (_exports, _controller, _object, _service, _groupDelete, _decorators, _discourseI18n, _ajax, _computed, _pwaUtils, _application, _shareTopic, _url, _category) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const Tab = _object.default.extend({
    init() {
      this._super(...arguments);
      this.setProperties({
        route: this.route || `group.${this.name}`,
        message: _discourseI18n.default.t(`groups.${this.i18nKey || this.name}`)
      });
    }
  });
  var _default = _exports.default = _controller.default.extend((_dec = (0, _decorators.default)("model.name"), _dec2 = (0, _decorators.default)("model.full_name"), _dec3 = (0, _decorators.default)("model.full_name"), _dec4 = (0, _decorators.default)("showMessages", "model.user_count", "model.request_count", "canManageGroup", "model.allow_membership_requests"), _dec5 = (0, _decorators.default)("model.has_messages", "model.is_group_user", "currentUser.can_send_private_messages"), _dec6 = (0, _decorators.default)("model.messageable"), _dec7 = (0, _decorators.default)("model", "model.automatic"), (_obj = {
    userController: (0, _controller.inject)("user"),
    user: (0, _computed.alias)("userController.model"),
    application: (0, _controller.inject)(),
    dialog: (0, _service.service)(),
    currentUser: (0, _service.service)(),
    router: (0, _service.service)(),
    composer: (0, _service.service)(),
    modal: (0, _service.service)(),
    topicTotal: 0,
    replyTotal: 0,
    userTotal: 0,
    groupOwner: {},
    groupMemberList: [],
    counts: null,
    showing: "members",
    destroying: null,
    showTooltip: false,
    moderatorMes: null,
    moderatorAbout: null,
    searchValue: '',
    isMobile: (0, _object.computed)(function () {
      // 这里可以使用你之前提到的任何一种方法来判断是否为移动端
      // 例如，使用屏幕宽度作为判断依据
      return window.innerWidth <= 768;
    }),
    init() {
      this._super(...arguments);
      // this.addKeyboardEventListener(); // 添加键盘事件监听器
    },
    // willDestroyElement() {
    //   this._super(...arguments);
    //   this.removeKeyboardEventListener(); // 移除键盘事件监听器
    // },

    // addKeyboardEventListener() {
    //   window.addEventListener('keydown', this.handleKeyDown.bind(this));
    // },

    // removeKeyboardEventListener() {
    //   window.removeEventListener('keydown', this.handleKeyDown.bind(this));
    // },

    // handleKeyDown(event) {
    //   if (event.key === 'Enter') {
    //     this.onEnterPress();
    //   }
    // },

    // onEnterPress() {
    //   let url = "/search";
    //   url = `${url}?q=${this.searchValue}`;
    //   location.href = url;
    // },

    listfun() {
      const likeLinks = document.querySelectorAll('.click-likes');
      likeLinks.forEach(link => {
        link.addEventListener('click', e => {
          const thumbsUpIcon = link.querySelector('.d-icon');
          const useElement = thumbsUpIcon.querySelector('use');
          const likenum = link.querySelector('.likenum');
          const clickedLink = e.currentTarget;
          const topicId = clickedLink.getAttribute('data-topic-id');
          let topicitem = this.session.topicList.topics.filter(item => item.relative_post_id == topicId);
          if (!topicId) return;
          if (topicitem[0].liked == true) {
            (0, _ajax.ajax)("/post_actions/" + topicId, {
              type: "DELETE",
              data: {
                post_action_type_id: 2
              }
            }).then(result => {
              let topiclist = this.session.topicList.topics;
              for (let i = 0; i < topiclist.length; i++) {
                if (topiclist[i].relative_post_id == topicId) {
                  topiclist[i].liked = false;
                }
              }
              likenum.innerText = Number(likenum.innerText) - 1;
              thumbsUpIcon.style.setProperty('color', '#a3afba', 'important');
              useElement.setAttribute('href', '#far-heart');
            }).catch(error => {
              this.dialog.alert({
                message: error.jqXHR.responseJSON.errors[0]
              });
            });
          }
          if (topicitem[0].liked == false || topicitem[0].liked == null) {
            (0, _ajax.ajax)("/post_actions", {
              type: "POST",
              data: {
                id: topicId,
                post_action_type_id: 2,
                flag_topic: false
              },
              returnXHR: true
            }).then(res => {
              let topiclist = this.session.topicList.topics;
              for (let i = 0; i < topiclist.length; i++) {
                if (topiclist[i].relative_post_id == topicId) {
                  topiclist[i].liked = true;
                }
              }
              likenum.innerText = Number(likenum.innerText) + 1;
              if (useElement) {
                useElement.setAttribute('href', '#heart');
                thumbsUpIcon.style.setProperty('color', 'red', 'important');
                thumbsUpIcon.classList.add('animate');
              }
              thumbsUpIcon.addEventListener('animationend', () => {
                thumbsUpIcon.classList.remove('animate');
              }, {
                once: true
              });
            }).catch(error => {
              this.dialog.alert({
                message: error.jqXHR.responseJSON.errors[0]
              });
            });
          }
        });
      });
      const Clickshare = document.querySelectorAll('.click-share');
      if (Clickshare) {
        Clickshare.forEach(link => {
          link.addEventListener('click', e => {
            const clickedLink = e.currentTarget;
            const share = clickedLink.getAttribute('data-topic-id');
            const slug = clickedLink.getAttribute('data-topic-slug');
            let url = `/t/${slug}/${share}`;
            let topicitem = this.session.topicList.topics.filter(item => item.id == share);
            (0, _pwaUtils.nativeShare)(this.capabilities, {
              url: url
            }).catch(() => {
              (0, _application.getOwner)(this).lookup("service:modal").show(_shareTopic.default, {
                model: {
                  category: topicitem[0].category,
                  topic: topicitem[0]
                }
              });
            });
          });
        });
      }
    },
    setOwenerMessage() {
      (0, _ajax.ajax)("/groups/" + this.model.name + "/members.json", {
        offset: 0,
        asc: true
      }).then(_result => {
        if (!_result.owners.length) {
          this.set("groupOwner", {
            username: 'secrecy',
            title: 'secrecy',
            avatar_template: '/letter_avatar_proxy/v4/letter/u/b5e925/{size}.png'
          });
          this.set("groupMemberList", []);
        } else {
          const owner = _result.owners[0];
          this.set("groupOwner", owner);
          this.set("groupMemberList", _result.members);
          return Promise.all([(0, _ajax.ajax)("/u/" + owner.username + "/summary.json").then(res => {
            this.set('moderatorMes', res.user_summary);
          }), (0, _ajax.ajax)("/u/" + owner.username + ".json").then(res => {
            this.set('moderatorAbout', res.user);
          })]);
        }
      }).catch(error => {
        // 在这里处理请求错误，例如显示错误消息
        console.error('请求失败：', error);
      }).finally(() => {
        // 清理工作，如果需要在所有情况下都执行，则放在finally中
      });
    },
    goToPreviousPage() {
      window.history.back();
    },
    searchCate() {
      return '%23' + this.model.full_name.toLowerCase().replace(/ /g, '-');
    },
    currentCateMesg() {
      setTimeout(() => {
        this.listfun();
      }, 500);
      (0, _ajax.ajax)(`/moyun/group_summary`, {
        type: "POST",
        data: {
          name: this.model.full_name
        }
      }).then(res => {
        this.set("topicTotal", res.post_cnt ? res.post_cnt : 0);
        this.set("replyTotal", res.reply_cnt ? res.reply_cnt : 0);
        this.set("userTotal", res.creator_cnt ? res.creator_cnt : 0);
      });
    },
    tabs(showMessages, userCount, requestCount, canManageGroup, allowMembershipRequests) {
      const membersTab = Tab.create({
        name: "members",
        route: "group.index",
        icon: "users",
        i18nKey: "members.title",
        count: userCount
      });
      const defaultTabs = [membersTab, Tab.create({
        name: "activity"
      })];
      if (canManageGroup && allowMembershipRequests) {
        defaultTabs.push(Tab.create({
          name: "requests",
          i18nKey: "requests.title",
          icon: "user-plus",
          count: requestCount
        }));
      }
      if (showMessages) {
        defaultTabs.push(Tab.create({
          name: "messages",
          i18nKey: "messages"
        }));
      }
      if (canManageGroup) {
        defaultTabs.push(Tab.create({
          name: "manage",
          i18nKey: "manage.title",
          icon: "wrench"
        }));
      }
      defaultTabs.push(Tab.create({
        name: "permissions",
        i18nKey: "permissions.title"
      }));
      return defaultTabs;
    },
    showMessages(hasMessages, isGroupUser) {
      if (!this.currentUser?.can_send_private_messages) {
        return false;
      }
      if (!hasMessages) {
        return false;
      }
      return isGroupUser || this.currentUser && this.currentUser.admin;
    },
    displayGroupMessageButton(messageable) {
      return this.currentUser && messageable;
    },
    canManageGroup(model, automatic) {
      return this.currentUser && (this.currentUser.canManageGroup(model) || model.can_admin_group && automatic);
    },
    messageGroup() {
      this.composer.openNewMessage({
        recipients: this.get("model.name"),
        hasGroups: true
      });
    },
    destroyGroup() {
      this.set("destroying", true);
      const model = this.model;
      this.dialog.deleteConfirm({
        title: _discourseI18n.default.t("admin.groups.delete_confirm", {
          group: model.name
        }),
        bodyComponent: _groupDelete.default,
        bodyComponentModel: model,
        didConfirm: () => {
          model.destroy().catch(error => {
            // eslint-disable-next-line no-console
            console.error(error);
            this.dialog.alert(_discourseI18n.default.t("admin.groups.delete_failed"));
          }).then(() => {
            this.router.transitionTo("groups.index");
          }).finally(() => {
            this.set("destroying", false);
          });
        },
        didCancel: () => this.set("destroying", false)
      });
    },
    toggleDeleteTooltip() {
      this.toggleProperty("showTooltip");
    },
    refuse(event) {
      event.stopPropagation();
      console.log('refuse');
    },
    pass(event) {
      event.stopPropagation();
      console.log('pass');
    }
  }, (_applyDecoratedDescriptor(_obj, "setOwenerMessage", [_dec], Object.getOwnPropertyDescriptor(_obj, "setOwenerMessage"), _obj), _applyDecoratedDescriptor(_obj, "goToPreviousPage", [_object.action], Object.getOwnPropertyDescriptor(_obj, "goToPreviousPage"), _obj), _applyDecoratedDescriptor(_obj, "searchCate", [_dec2], Object.getOwnPropertyDescriptor(_obj, "searchCate"), _obj), _applyDecoratedDescriptor(_obj, "currentCateMesg", [_dec3], Object.getOwnPropertyDescriptor(_obj, "currentCateMesg"), _obj), _applyDecoratedDescriptor(_obj, "tabs", [_dec4], Object.getOwnPropertyDescriptor(_obj, "tabs"), _obj), _applyDecoratedDescriptor(_obj, "showMessages", [_dec5], Object.getOwnPropertyDescriptor(_obj, "showMessages"), _obj), _applyDecoratedDescriptor(_obj, "displayGroupMessageButton", [_dec6], Object.getOwnPropertyDescriptor(_obj, "displayGroupMessageButton"), _obj), _applyDecoratedDescriptor(_obj, "canManageGroup", [_dec7], Object.getOwnPropertyDescriptor(_obj, "canManageGroup"), _obj), _applyDecoratedDescriptor(_obj, "messageGroup", [_object.action], Object.getOwnPropertyDescriptor(_obj, "messageGroup"), _obj), _applyDecoratedDescriptor(_obj, "destroyGroup", [_object.action], Object.getOwnPropertyDescriptor(_obj, "destroyGroup"), _obj), _applyDecoratedDescriptor(_obj, "toggleDeleteTooltip", [_object.action], Object.getOwnPropertyDescriptor(_obj, "toggleDeleteTooltip"), _obj), _applyDecoratedDescriptor(_obj, "refuse", [_object.action], Object.getOwnPropertyDescriptor(_obj, "refuse"), _obj), _applyDecoratedDescriptor(_obj, "pass", [_object.action], Object.getOwnPropertyDescriptor(_obj, "pass"), _obj)), _obj)));
});