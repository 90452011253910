define("discourse/plugins/moyun/discourse/routes/notification", ["exports", "discourse/lib/ajax", "@ember/service", "discourse/models/category", "discourse/models/topic-list", "discourse/routes/build-topic-route", "discourse/routes/discourse", "discourse-i18n"], function (_exports, _ajax, _service, _category, _topicList, _buildTopicRoute, _discourse, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    store: (0, _service.service)(),
    historyStore: (0, _service.service)(),
    site: (0, _service.service)(),
    titleToken() {
      return _discourseI18n.default.t(`groups.topics`);
    },
    async _retrieveTopicList(category, transition, modelParams) {
      const findOpts = (0, _buildTopicRoute.filterQueryParams)(modelParams, this.routeConfig);
      const extras = {
        cached: this.historyStore.isPoppedState
      };
      let listFilter = `c/${_category.default.slugFor(category)}/${category.id}/l/latest`;
      const topicList = await (0, _buildTopicRoute.findTopicList)(this.store, this.topicTrackingState, listFilter, findOpts, extras);
      _topicList.default.hideUniformCategory(topicList, category);
      return topicList;
    },
    async model(params, transition) {
      let cate_path = "application-of-group" + '/' + 11;
      const category = _category.default.findBySlugPathWithID(cate_path);
      const topicListPromise = this._retrieveTopicList(category, transition, params);
      return await topicListPromise;
    }
  });
});