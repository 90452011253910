define("discourse/plugins/moyun/discourse/routes/sub-banner", ["exports", "discourse/routes/discourse", "discourse-i18n", "discourse/lib/ajax"], function (_exports, _discourse, _discourseI18n, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    beforeModel() {
      if (!this.currentUser || !this.currentUser.admin) {
        this.router.transitionTo("/latest");
      }
    },
    async model() {
      let {
        data: res
      } = await (0, _ajax.ajax)('/moyun/home_banners?type=sub_banner');
      // console.log('sub', res);
      return res;
    }
  });
});