define("discourse/plugins/moyun/discourse/components/center-mobile-swiper/cmswiper", ["exports", "@ember/component", "@ember/service", "discourse/components/d-button", "@ember/object", "@glimmer/tracking", "discourse/lib/ajax", "@ember/template-factory"], function (_exports, _component, _service, _dButton, _object, _tracking, _ajax, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _AdvertiseSwiper;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let AdvertiseSwiper = _exports.default = (_class = (_AdvertiseSwiper = class AdvertiseSwiper extends _component.default {
    // swiperList = [
    //   {
    //     title: "标题11",
    //     pic: "https://obj.shine.cn/files/2024/04/18/7471e3ad-2b04-4dfe-9818-4b221ab54140_0.jpg",
    //   },
    //   {
    //     title: "标题22",
    //     pic: "https://obj.shine.cn/files/2024/05/01/84723faf-2aa5-4191-bbce-4cb167d7005b_0.jpg",
    //   },
    //   {
    //     title: "标题33",
    //     pic: "https://obj.shine.cn/files/2024/06/09/8893221f-e17b-4640-8037-fe5a99b77664_0.jpg",
    //   },
    //   {
    //     title: "标题44",
    //     pic: "https://obj.shine.cn/files/2024/04/26/abf475a2-b392-423c-b193-8f0db598f033_0.jpg",
    //   },
    // ];
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "swiperList", _descriptor, this);
      this.getBannerList();
    }
    async getBannerList() {
      const {
        data: res1
      } = await (0, _ajax.ajax)("/moyun/home_banners?type=sub_banner");
      this.swiperList = res1.slice(0, 4);
    }
    didInsertElement() {
      // this._super(...arguments);
      const swiper1 = new Swiper(".cmswiper", {
        //modules: [ Pagination, Autoplay, Navigation ],
        loop: true,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          autoplay: true
        }
      });
    }
  }, (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{!-- <div class="cmswiper-container">
        <div class="swiper cmswiper">
          <div class="swiper-wrapper">
            {{#each this.swiperList as |item|}}
              <div class="swiper-slide">
                <img src={{item.pic}} />
              </div>
            {{/each}}
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div> --}}
      {{#if this.swiperList.length}}
      <div class="cmswiper-container" {{didInsert this.didInsertElement}}>
        <div class="swiper cmswiper">
          <div class="swiper-wrapper">
            {{#each this.swiperList as |item|}}
               <a
                  href={{item.link_url}}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img src={{item.image_url}} alt={{item.title}} />
                </a>
            {{/each}}
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
      {{/if}}
    
  */
  {
    "id": "10E5/J6i",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"swiperList\",\"length\"]],[[[1,\"    \"],[11,0],[24,0,\"cmswiper-container\"],[4,[31,1],[[30,0,[\"didInsertElement\"]]],null],[12],[1,\"\\n      \"],[10,0],[14,0,\"swiper cmswiper\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"swiper-wrapper\"],[12],[1,\"\\n\"],[42,[28,[31,3],[[28,[31,3],[[30,0,[\"swiperList\"]]],null]],null],null,[[[1,\"             \"],[10,3],[15,6,[30,1,[\"link_url\"]]],[14,\"rel\",\"noopener noreferrer\"],[14,\"target\",\"_blank\"],[12],[1,\"\\n                \"],[10,\"img\"],[15,\"src\",[30,1,[\"image_url\"]]],[15,\"alt\",[30,1,[\"title\"]]],[12],[13],[1,\"\\n              \"],[13],[1,\"\\n\"]],[1]],null],[1,\"        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"swiper-pagination\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"item\"],false,[\"if\",\"didInsert\",\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/moyun/discourse/components/center-mobile-swiper/cmswiper.js",
    "isStrictMode": true
  }), _AdvertiseSwiper), _AdvertiseSwiper), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "swiperList", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getBannerList", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getBannerList"), _class.prototype)), _class);
});